.blog-details-reply-box,
.blog-section .service-content .buttons,
.comment-reply,
.comment-time,
.contribut-icon,
.counts,
.social ul li,
.social ul li a,
nav.navbar.navbar-expand-md.btco-hover-menu,
ul.working-list li {
    display: inline-block
}

.about-content h2,
.breadcrum-inner h2,
.btn-button-2,
.comment-reply a,
.comment-time,
.faq-items .panel-title>a,
.ft-t-comm a,
.ft-widget h2,
.ft-widget ul li a,
.hadding-text-area h2,
.service-c-dec>h2,
.slider-content h2,
.widget-slide-content,
.widget-title,
h3.comment-title,
ul.working-list li a {
    text-transform: capitalize
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
    margin: 0;
    padding: 0
}

.about-content h2 span:after,
.video-img:after,
span.testi-title label:after {
    content: ""
}

.black-c,
.home-1 a,
.home-1 h1,
.home-1 h2,
.home-1 h3,
.home-1 h4,
.home-1 h5,
.home-1 h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #181d31
}

img {
    max-width: 100%;
    height: auto
}

body,
html {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    height: 100%;
    color: #6e789b
}

.ft-widget h2,
.popin,
.slider-content h1,
.slider-content h2,
.title {
    font-family: Poppins
}

ol,
ul {
    list-style: none
}

.btn,
.btn:hover,
.smoth,
.smoth:hover,
a,
a:hover,
button,
button:hover {
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out
}

.btn:focus,
.btn:hover:focus,
a,
a:focus,
a:hover,
a:hover:focus,
button:focus,
button:hover:focus {
    text-decoration: none;
    outline: 0;
    border: none;
    -webkit-border: none;
    -moz-border: none;
    -ms-border: none;
    -o-border: none
}

.home-2 .blue-c,
.home-2 .contribut-content a:hover,
.home-2 .contribut-icon i,
.home-2 .copyright .blue-c,
.home-2 .h-top-item p>span,
.home-2 .ques-accodium .panel-title>a:hover,
.home-2 .service-content a:hover,
.home-2 .service-icon i,
.home-2 .social ul li a:hover,
.home-2 a.black-c:hover {
    color: #ffcb3d
}

.blue-bg {
    background-color: #2d3392;
    color: #fff
}

.home-2 .blue-bg {
    background-color: #ffcb3d;
    color: #181d31
}

.home-3 .blue-bg {
    background-color: #893cee;
    color: #fff
}

.home-3 .blue-c,
.home-3 .copyright .blue-c {
    color: #893cee
}

.black-bg,
.home-3 a.blue-bg:hover,
a.blue-bg:hover {
    background-color: #181d31;
    color: #fff
}

.blue-c,
.call-num.h-top-item a,
.faq-items .panel-title>a.collapsed,
.home-2 .banner-text p,
.home-2 .contribut-content a,
.home-3 .service-content a,
.home-3 .title,
.title {
    color: #2d3392
}

a.black-bg:hover,
div a.blue-bg {
    background-color: #28a745;
    color: #fff
}

.pt-80 {
    padding-top: 80px
}

.pb-80 {
    padding-bottom: 80px
}

.client-area-count,
.mt-80 {
    margin-top: 80px
}

.btn-button-2 {
    padding: 8px 20px;
    font-weight: 600
}

.btn-button-2:hover {
    border: 1px solid transparent
}

.btn-button-2:focus,
.btn-button-2:hover:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid transparent
}

.btn-button-1 {
    padding: 9px 28px;
    border-radius: 100px;
    background: 0 0;
    border: 2px solid #28a74569;
    font-weight: 700
}

.btn-button-1:hover {
    border: 2px solid transparent;
    background-color: #2d3392;
    color: #fff
}

.btn-button-1:focus,
.btn-button-1:hover:focus {
    border: 2px solid transparent;
    box-shadow: none;
    background: #2d3392;
    color: #fff
}

.bg-btn {
    background-color: #28a74530
}

.comment-reply a:hover,
.details-page a.flat-icons:hover,
.faq-items .active a,
.faq-items .panel-title>a,
.faq-items .panel-title>a:hover,
.ques-accodium .panel-title>a:hover,
.social-links a:hover,
.title:hover,
.widget-slide-content>a:hover {
    color: #00bad0
}

.team-dec h2,
.title {
    font-size: 18px;
    font-weight: 700
}

.hadding-text-area h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px
}

.header-top {
    background-color: #f7f7f7
}

.h-top-item p>span {
    color: #28a745;
    margin-right: 5px
}

.header-menu {
    background-color: #2d3392;
    text-align: center
}

.search-h-icon button:focus,
.search-h-icon button:hover:focus {
    box-shadow: none;
    border: 1px solid transparent
}

input.form-control.header-s-input:focus,
input.form-control.header-s-input:hover:focus {
    box-shadow: none
}

input.form-control.header-s-input {
    padding: 1.45rem 36px;
    border-radius: 0;
    border: none;
    background: #2d3392;
    color: #fff
}

input.form-control.header-s-input::placeholder {
    color: #d4d3d3
}

.search-h-icon button {
    padding: 1.17rem 27px;
    border-radius: 0;
    font-size: 18px;
    cursor: pointer;
    background: #05cde4;
    color: #fff
}

.banner-bg,
.banner-section,
.search-h-icon button:hover {
    background: #2d3392
}

.dropdown-toggle::after {
    float: right;
    margin-top: 11px
}

.btco-hover-menu a,
.navbar>li>a {
    text-transform: capitalize;
    padding: 19px 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 600
}

.about-content h2 span,
.faq-top-contetn h2,
.fb-link a,
.taq-item h2,
.team-dec h2,
.team-dec h3,
a.social-links {
    text-transform: uppercase
}

.btco-hover-menu .active a,
.btco-hover-menu .active a:focus,
.btco-hover-menu .active a:hover,
.btco-hover-menu li a:focus,
.btco-hover-menu li a:hover,
.navbar>.show>a,
.navbar>.show>a:focus,
.navbar>.show>a:hover {
    color: #fff;
    background: 0 0;
    outline: 0
}

.dropdown-menu {
    padding: 0;
    margin: 0;
    border: 0 solid transition !important;
    border-radius: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btco-hover-menu .collapse ul ul,
.btco-hover-menu .collapse ul ul ul,
.btco-hover-menu .collapse ul ul ul.dropdown-menu,
.btco-hover-menu .collapse ul ul.dropdown-menu,
.btco-hover-menu .collapse ul>li:hover>a {
    background: #323857
}

.btco-hover-menu .collapse ul ul ul>li:hover>a,
.btco-hover-menu .collapse ul ul>li:hover>a,
.navbar .show .dropdown-menu>li>a:focus,
.navbar .show .dropdown-menu>li>a:hover,
.testimonial-item .owl-dot.active {
    background: #00bad0
}

.btco-hover-menu .collapse ul ul ul ul,
.btco-hover-menu .collapse ul ul ul ul.dropdown-menu {
    background: #f5f5f5
}

ul.dropdown-menu>li>a {
    padding: 12px 14px
}

.btco-hover-menu {
    background: 0 0;
    margin: 0;
    padding: 0;
    min-height: 20px
}

@media only screen and (max-width:991px) {
    .btco-hover-menu .show>.dropdown-toggle::after {
        transform: rotate(-90deg)
    }
}

@media only screen and (min-width:991px) {

    .btco-hover-menu .collapse ul li,
    .btco-hover-menu .collapse ul ul li,
    .btco-hover-menu .collapse ul ul ul li {
        position: relative
    }

    .btco-hover-menu .collapse ul li:hover>ul,
    .btco-hover-menu .collapse ul ul li:hover>ul,
    .btco-hover-menu .collapse ul ul ul li:hover ul {
        display: block
    }

    .btco-hover-menu .collapse ul ul {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 250px;
        display: none
    }

    .btco-hover-menu .collapse ul ul ul {
        position: absolute;
        top: 0;
        left: 100%;
        min-width: 250px;
        display: none
    }

    .btco-hover-menu .collapse ul ul ul ul {
        position: absolute;
        top: 0;
        left: -100%;
        min-width: 250px;
        display: none;
        z-index: 1
    }
}

.breadcrumbs,
.careers_apply_form .form-group,
.ques-accodium a.collapsed,
.slider-img,
.slider-items,
.team-img,
.twite-date,
.video-img {
    position: relative
}

.slider-contents {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 9
}

.slider-content h1 {
    font-size: 47px;
    font-weight: 700;
    padding-right: 17%;
    line-height: 60px;
    margin-bottom: 7px;
    color: #fff
}

.slider-content h2 {
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 13px
}

.slider-content p {
    color: #fff;
    margin-bottom: 15px
}

.banner-section a,
.client-count-item:hover .counts .title,
.details-page a.flat-icons,
.ft-t-comm a:hover,
.ft-widget ul li a:hover,
.home-2 .service-item1 .service-icon i,
.home-2 .subscrib-area .button .btn-button:hover,
.home-3 .button a,
.home-3 .service-item1 .service-icon i,
.home-3 .working-time,
.service-item1 .service-icon i,
.service-item:hover .service-icon i,
a.brand_mail {
    color: #fff
}

.banner-text p {
    font-size: 36px;
    line-height: 40px;
    color: #fff
}

.banner-section .bg-btn {
    background-color: rgb(255 255 255 / 59%);
    border-color: #ffffff87
}

.banner-section a:hover,
.subscrib-area .button .btn-button:hover {
    background-color: #2d3392;
    border-color: transparent
}

.service-bg {
    background-size: cover;
    background-position: center;
    padding: 52px 0;
    z-index: 0;
    opacity: 0
}

.client-items a:hover img,
.home-2 .service-item1 .service-bg,
.home-3 .service-item1 .service-bg,
.partner-item .item img:hover,
.service-item1 .service-bg,
.service-item:hover .service-bg {
    opacity: 1
}

.service-icon {
    width: 18%;
    height: 100%;
    float: left;
    min-height: 100px;
    margin-right: 20px;
    position: relative
}

.pop-video,
.service-icon i {
    margin: auto;
    right: 0;
    text-align: center
}

.contribut-icon i,
.copyright .blue-c,
.service-content a:hover,
.social ul li a:hover {
    color: #28a745
}

.service-content {
    float: right;
    width: 76%;
    padding-top: 20px
}

.service-icon i {
    font-size: 30px;
    padding: 12px 0;
    color: #28a745;
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%
}

.service-content a {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: #2d3392
}

.service-item {
    overflow: hidden
}

.contribut-sort-dec h2 {
    font-weight: 700;
    font-size: 25px;
    padding-right: 15rem;
    line-height: 27px;
    margin-bottom: 18px
}

.contribut-icon {
    width: 60px;
    float: left;
    min-height: 67px
}

.contribut-content p {
    margin-top: 8px
}

.contribut-content {
    overflow: hidden;
    padding-top: 10px;
    display: block
}

.pop-video,
.video-img:after {
    position: absolute;
    top: 0;
    left: 0
}

.pop-video {
    width: 80px;
    height: 80px;
    bottom: 0;
    border-radius: 100%;
    border: 15px solid rgba(20, 35, 37, .35);
    font-size: 27px;
    padding: 12px 0;
    background: #00bad0;
    z-index: 2
}

a.pop-video i:before {
    font-size: 23px;
    color: #fff
}

.pop-video:focus:hover,
.pop-video:hover {
    background: #2d3392;
    border: 15px solid #00bad0
}

.video-img:after {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(5, 11, 33, .9) 0, rgba(57, 63, 90, .62) 88%);
    z-index: 1
}

.client-area-count .textimonial-dec-inner h2 {
    padding-left: 0;
    padding-right: 23%
}

.client-area-count .textimonial-dec-inner p {
    padding-right: 13%;
    padding-left: 0
}

.client-count-item {
    background-color: #f7fbff;
    padding: 29px 26px;
    margin: 15px 0;
    border-radius: 8px;
    box-shadow: 0 0 9px 1px #e4e4e4;
    cursor: pointer;
    transition: transform .2s;
    z-index: 0
}

ul.working-list li a,
ul.working-list li a.active,
ul.working-list li a:hover {
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    cursor: pointer
}

.counts span {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 12px;
    display: block
}

.client-count-item:hover {
    color: #fff;
    transform: scale(1.2);
    clear: both;
    z-index: 999;
    overflow: hidden;
    background-color: #28a745;
    box-shadow: 0 4px 32px -3px #000000a8
}

.counts>p {
    font-size: 18px
}

.work-section {
    background-color: #f0f6f7
}

ul.working-list li a {
    display: inline-block;
    padding: 14px 30px 10px;
    background: #fff;
    margin: 0 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 #d8d8d8;
    color: #6e789b;
    font-weight: 600;
    font-size: 14px;
    transition: .4s ease-in-out
}

ul.working-list li a.active,
ul.working-list li a:hover {
    background: #00bad0;
    color: #fff;
    box-shadow: 0 1px 20px 0 #00bad0;
    transition: .4s ease-in-out
}

.service-details .nav-tabs--left .nav-link,
.service-details .nav-tabs--left .nav-link:hover {
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out
}

ul.working-list.text-center.nav.nav-tabs {
    text-align: center;
    margin: 0 auto;
    display: block;
    border-bottom: none
}

.hadidng-2 h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 12px
}

.client-items img,
.partner-item .item img {
    opacity: .5
}

.texti-img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px
}

.texti-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid #fff;
    border-radius: 100%;
    box-shadow: 0 0 8px 0 #b1b1b1
}

span.testi-title label {
    position: relative;
    display: inline-block;
    padding-left: 38px
}

span.testi-title label:after {
    width: 34px;
    height: 2px;
    background: #2d3392;
    position: absolute;
    left: 0;
    top: 50%
}

.testimonial-item .owl-dot {
    width: 10px;
    height: 10px;
    background: #6e789b;
    border-radius: 100%;
    display: inline-block;
    margin: 3px
}

.testimonial-item .owl-dots {
    text-align: center;
    margin-top: 50px
}

.about-content h2 {
    font-size: 30px;
    margin-bottom: 15px
}

.about-content h2 span {
    font-weight: 700;
    position: relative
}

.about-content h2 span:after {
    width: 100px;
    height: 1px;
    background: #00bad0;
    position: absolute;
    bottom: 7px;
    margin-left: 10px
}

.about-content {
    padding-right: 100px
}

.single-blog-content .btn {
    color: #232323;
    background: #fff;
    border: 1px solid #28a74557
}

.single-blog-content span {
    padding: 0 13px;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 7px;
    font-size: 13px
}

.blog-page .single-blog,
.single-blog-content {
    margin-top: 30px
}

.single-blog-content>a {
    padding: 0 0 7px
}

.ques-accodium ul li {
    display: block;
    line-height: 37px
}

.ques-accodium ul li a {
    color: #6e789b;
    font-size: 16px;
    display: block;
    border-bottom: 1px solid #9398a9;
    margin-bottom: 16px
}

.ques-accodium ul li a>span {
    text-align: right;
    display: inline-block;
    float: right;
    font-size: 12px;
    padding: 12px 0
}

.ques-accodium {
    margin-top: 40px
}

.ques-accodium .panel-title>a {
    display: block;
    font-size: 16px;
    line-height: 40px;
    border-bottom: 1px solid #b5bace;
    color: #6e789b;
    margin-bottom: 12px
}

.question-area h2 {
    padding-right: 12rem;
    line-height: 34px;
    margin-bottom: 10px
}

.ques-accodium .panel-title>a.collapsed:after {
    content: "+";
    float: right;
    font-size: 20px;
    margin-top: 5px
}

.ques-accodium .panel-title>a:after {
    content: "-";
    float: right;
    font-size: 20px;
    margin-top: 5px
}

.contact-form {
    padding: 22px 30px;
    background-color: #f0f9fa;
    border: 1px dashed #c3cae1
}

.contact-some-dec p {
    padding-top: 10px;
    padding-bottom: 11px
}

.button.form-group {
    padding-top: 6px;
    margin: 0
}

.contact-form input.form-control {
    border: none;
    padding: 10px;
    display: block
}

.contact-form input.form-control:focus {
    border: none;
    outline: 0;
    box-shadow: none
}

.contact-form textarea.form-control.form-message {
    border: none
}

.contact-form textarea.form-control.form-message:focus {
    outline: 0;
    border: none;
    box-shadow: none
}

.career_ap_btn button,
.contact-form a.btn.btn-button {
    background: #28a745
}

.contact-form a.btn.btn-button:hover,
.home-2 .search-h-icon button:hover {
    background: #2d3392;
    color: #fff
}

.subscrib-dec h2 {
    font-size: 42px;
    color: #fff
}

.subscrib-area .button .btn-button {
    background: #28a745;
    border: 2px solid #ffffffa6;
    color: #fff
}

.ft-info-content .social ul li a>i {
    margin-right: 8px
}

.ft-info-content ul li>i {
    margin-right: 10px
}

.social ul li a {
    color: #fff;
    padding-right: 22px
}

.ft-widget h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 700
}

.ft-widget ul li a {
    color: #808dae;
    line-height: 33px
}

.ft-info-content p,
.ft-widget p {
    color: #808dae
}

.ft-twite-top a {
    font-size: 24px;
    color: #fff;
    margin-bottom: 11px;
    display: block
}

.twite-date {
    color: #6e789b;
    padding-bottom: 21px;
    margin-bottom: 23px
}

.twite-date:after {
    content: "";
    width: 22%;
    height: 1px;
    background: #d4d4d463;
    position: absolute;
    left: 0;
    bottom: 0
}

.ft-t-comm {
    display: inline-block;
    margin-top: 19px
}

.ft-t-icon {
    display: inline-block;
    float: right;
    font-size: 60px;
    color: #22a0f2
}

.ft-t-comm a {
    color: #7481a0
}

.ft-t-comm a span {
    margin-right: 6px
}

.ft-twite-comment {
    display: block;
    margin-top: 14px
}

.home-2 .contact-form a.btn.btn-button,
.home-2 .header-menu,
.home-2 .testimonial-item .owl-dot.active,
.home-2 a.black-bg:hover {
    background-color: #ffcb3d
}

.home-2 .search-h-icon button {
    background: #ffdb78;
    color: #323857
}

.home-2 .btco-hover-menu a,
.home-2 .navbar>li>a {
    color: #323857;
    font-weight: 700
}

.home-2 .btco-hover-menu .collapse ul>li:hover>a {
    color: #333;
    background: #ffdb78
}

.home-2 .btco-hover-menu .collapse ul ul,
.home-2 .btco-hover-menu .collapse ul ul.dropdown-menu {
    background: #ffdb78
}

.home-2 .btco-hover-menu .collapse ul ul>li:hover>a,
.home-2 .navbar .show .dropdown-menu>li>a:focus,
.home-2 .navbar .show .dropdown-menu>li>a:hover {
    background: #323857;
    color: #fff
}

.home-2 .banner-section {
    background: #ffcb3d
}

.home-2 .banner-section .bg-btn:hover {
    color: #fff;
    background-color: #323857;
    border-color: transparent
}

.home-2 .banner-section .bg-btn {
    background-color: rgba(50, 56, 87, .34);
    border-color: #717480;
    color: #333
}

.home-2 .client-count-item:hover {
    background-color: #ffcb3d;
    box-shadow: 0 4px 32px -3px #efb518
}

.home-2 .client-count-section,
.home-2 .question-contact,
.home-2 .testimonial-section {
    background: #fbf8f0
}

.home-2 .btn-button-1 {
    border: 2px solid #ffcb3d
}

.home-2 .btn-button-1:hover {
    border: 2px solid transparent
}

.button a,
.home-2 .service-content a {
    color: #333
}

.home-2 .subscrib-area .button .btn-button {
    background: #d4ad42;
    border: 2px solid #a27f1f;
    color: #2d3392;
    font-weight: 700
}

.home-2 .bg-btn {
    background-color: rgba(204, 198, 97, .5);
    color: #333;
    border: 2px solid #ffcb3e
}

.home-2 .bg-btn:hover {
    background-color: #333;
    border: 1px solid transparent;
    color: #fff
}

.header-bottom,
.home-3 .header-top {
    border-bottom: 1px solid #303446
}

.home-2 ul.working-list li a.active,
.home-2 ul.working-list li a:hover {
    background: #ffcb3d;
    color: #333;
    box-shadow: 0 1px 20px 0 #ffc21d
}

.home-2 .work-section {
    background-color: #fff
}

.home-2 .contact-form a.btn.btn-button:hover {
    background-color: #333;
    color: #fff
}

.home-2 .twite-date:after {
    background: #c79919
}

.home-3 .header-center,
.home-3 .header-menu,
.home-3 .header-top {
    background-color: #181d31
}

.home-2 .service-bg,
.home-3 .service-bg {
    opacity: 0
}

.home-3 .slider-content h2 {
    color: #fff;
    padding: 0 14px;
    font-size: 38px;
    line-height: 47px;
    font-weight: 600
}

.header-bottom {
    border-top: 1px solid #303446;
    position: relative
}

.home-3 .slider-img:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(to top, rgba(33, 44, 87, .52) 0, #2d3392 100%);
    top: 0;
    left: 0
}

.home-3 .btco-hover-menu .collapse ul ul>li:hover>a,
.home-3 .navbar .show .dropdown-menu>li>a:focus,
.home-3 .navbar .show .dropdown-menu>li>a:hover,
.home-3 .search-h-icon button:hover,
.home-3 div.login-btn a.blue-bg:hover {
    background: #fff;
    color: #893cec
}

.home-3 .search-h-icon button {
    background: #893ced;
    padding: .9rem 22px
}

.home-3 .btco-hover-menu a,
.home-3 .navbar>li>a {
    padding: 15px
}

.home-3 .bg-btn {
    background-color: rgba(29, 28, 28, .3);
    border-color: #fff
}

.home-3 .btco-hover-menu .collapse ul ul,
.home-3 .btco-hover-menu .collapse ul ul.dropdown-menu,
.home-3 .btco-hover-menu .collapse ul>li:hover>a {
    background: #893cec
}

.home-3 input.form-control.header-s-input {
    background: #fff;
    color: #333;
    padding: 1.1rem 36px
}

.home-3 input.form-control.header-s-input::placeholder {
    color: #565252
}

.home-3 .banner-text p {
    padding: 0 18rem;
    margin-bottom: 19px;
    font-weight: 700;
    font-size: 35px
}

.home-3 .banner-section {
    background: #893cee;
    padding: 0
}

.home-3 .banner-section .bg-btn {
    background-color: rgba(100, 34, 185, .25);
    border-color: #ad77f3
}

.home-3 .banner-section .bg-btn:hover {
    background-color: #fff;
    color: #893cee;
    border-color: transparent
}

.home-3 .btn-button-1 {
    border: 2px solid #893ced
}

.home-3 .blog-section .buttons a.btn-button-2:hover,
.home-3 .service-section .button a.btn-button-1:hover {
    background-color: #893ced;
    color: #fff
}

.home-3 .contribut-icon i,
.home-3 .h-top-item p>span,
.home-3 .ques-accodium .panel-title>a:hover,
.home-3 .service-content a:hover,
.home-3 .service-icon i,
.home-3 .service-section .button a.btn-button-1,
.home-3 .social ul li a:hover,
.home-3 .title:hover,
.home-3 .work-section a.btn-button-1 {
    color: #893ced
}

.home-3 .about-content h2 span:after,
.home-3 .contact-form a.btn.btn-button,
.home-3 .pop-video,
.home-3 .pop-video:focus:hover,
.home-3 .pop-video:hover,
.home-3 .testimonial-item .owl-dot.active {
    background: #893ced
}

.home-3 .client-count-item:hover,
.home-3 ul.working-list li a.active,
.home-3 ul.working-list li a:hover {
    background-color: #893cee;
    box-shadow: 0 4px 32px -3px #893cee;
    color: #fff
}

.home-3 .texti-img {
    width: 70px;
    height: 70px;
    margin: auto
}

.home-3 .testi-dec {
    margin-top: 14px;
    padding: 0 20%
}

.home-3 .testi-dec p {
    font-size: 28px;
    line-height: 38px
}

.blog-section .buttons a.btn-button-2 {
    color: #fff;
    font-size: 14px;
    font-weight: 400
}

.home-3 span.blog-date {
    position: absolute;
    top: 0;
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: 5px 10px;
    line-height: 19px
}

.home-3 .contact-form a.btn.btn-button:hover {
    background-color: #333;
    border-color: transparent;
    color: #fff
}

.home-3 .subscrib-area .button .btn-button {
    background: #7d39db;
    border: 2px solid #ad77f3;
    color: #fff
}

.home-3 .subscrib-area .button .btn-button:hover {
    background-color: #fff;
    color: #893ced;
    border-color: transparent
}

.home-3 .work-section a.btn-button-1:hover {
    background-color: #893ced;
    color: #fff;
    border-color: transparent
}

.home-3 .pop-video:focus:hover,
.home-3 .pop-video:hover {
    border: 15px solid #6416c7
}

.home-3 .twite-date:after {
    background: #7531ce
}

.breadcrum-inner {
    padding: 7rem 0
}

.team-contents,
.team-dec {
    padding-top: 30px
}

.bread-bg {
    background-size: cover;
    background-position: center
}

.breadcrum-inner h2 {
    font-size: 50px;
    font-weight: 700;
    z-index: 9;
    color: #f0f8ff
}

.service-details .nav-tabs--vertical {
    border-bottom: none;
    border-right: none;
    display: flex;
    flex-flow: column nowrap
}

.service-details .nav-tabs--left .nav-item+.nav-item {
    margin-top: .25rem;
    position: relative;
    outline: 0;
    box-shadow: none;
    border: none
}

.service-details .nav-tabs--left .nav-item {
    position: relative;
    margin-left: 19px
}

.gallery-img img,
.main_banner_slider img,
.team-img img {
    width: 100%
}

.service-details .nav-tabs--left .nav-link {
    white-space: nowrap;
    transition: .4s ease-in-out
}

.service-details .nav-tabs--left .nav-link:hover {
    background-color: transparent;
    border-color: transparent;
    color: #00bad0;
    transition: .4s ease-in-out
}

.service-details .nav-tabs--left .nav-link.active:hover,
.service-details .nav-tabs--left a:after {
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out
}

.service-details .nav-tabs--left .nav-link.active {
    border: none;
    color: #00bad0
}

.service-details .nav-tabs--left .nav-link.active:hover {
    background-color: transparent;
    border-color: transparent;
    transition: .4s ease-in-out
}

.service-details .nav-tabs--left a:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #323857;
    position: absolute;
    left: 0;
    border-radius: 100%;
    top: 42%;
    transition: .4s ease-in-out
}

.service-details .nav-tabs--left a.active:after,
.service-details .nav-tabs--left a:hover:after {
    background: #00bad0;
    content: "";
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out
}

.service-details .nav-tabs .nav-link {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.service-c-dec>h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 19px 0
}

.faq-top-contetn,
.gallery-content {
    margin-bottom: 30px
}

.fb-link a:hover {
    background: #00bad0;
    color: #fff
}

.fb-link a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: auto;
    width: 122px;
    text-align: center;
    background: #faf8ff;
    border-radius: 100px;
    padding: 5px 0 2px;
    font-size: 12px;
    font-weight: 700;
    color: #00bad0;
    letter-spacing: .5px
}

.team-dec h3,
.widget ul>li a,
a.brand_contact {
    color: #6e789b
}

.al-c,
.career_ap_btn,
.team-dec {
    text-align: center
}

.team-img img {
    border-radius: 15px
}

.team-dec h3 {
    font-size: 15px;
    padding-top: 10px;
    margin-bottom: 20px
}

.partner-item .item {
    margin-bottom: 50px
}

.testimonial-page .testimonial-item {
    padding-top: 50px
}

.faq-top-contetn h2 {
    font-size: 21px;
    font-weight: 700;
    margin: 15px
}

.faq-top-contetn p {
    color: #fff;
    padding: 0 12%
}

.taq-item h2 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 14px;
    cursor: pointer
}

.taq-item p {
    color: #fff;
    margin-bottom: 16px
}

.faq-items .panel.panel-default {
    background: 0 0;
    border: none;
    box-shadow: none
}

.faq-items .panel-heading {
    padding: 0;
    border: 0
}

.faq-items .panel-heading a.collapsed:before,
.faq-items .panel-heading a:before {
    content: "";
    transition: .5s;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    top: 43%;
    left: 0
}

.faq-items .panel-title>a {
    display: block;
    padding: 15px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    word-spacing: 3px;
    text-decoration: none;
    position: relative
}

.faq-items .panel-default>.panel-heading {
    color: #fff;
    background-color: transparent;
    border-color: transparent
}

.faq-items .panel-heading a.collapsed:before {
    background: #2d3392;
    position: absolute
}

.faq-items .panel-body {
    padding: 8px 17px;
    color: #2d3392
}

.faq-items .panel-heading a:before {
    background: #00bad0;
    position: absolute
}

.faq-items .panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: none
}

.widget-title {
    background: #00bad0;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px
}

.category,
.cluster {
    font-size: 11px;
    text-align: center;
    cursor: pointer
}

.widget-title i {
    margin: 0 7px
}

.widget {
    border-radius: 15px 15px 5px 5px;
    border: 1px solid #f6f6f6
}

.more.lable0 a {
    padding: 10px 22px;
    background: #f6f6f6;
    display: block;
    color: #333;
    font-size: 15px
}

.widget-recent-post .single-item {
    margin-bottom: 0
}

.widget-recent-post .img,
.widget-slide .img {
    float: left;
    display: inline-block;
    clear: both;
    overflow: hidden;
    padding: 0 20px;
    margin-top: 20px
}

.single-item {
    overflow: hidden;
    margin-bottom: 15px
}

.widget-recent-post a.title,
.widget-slide a.title {
    font-size: 16px
}

.widget-slide-content span {
    display: block;
    color: #fff;
    font-size: 14px
}

.widget-slide-content {
    margin: 14px 0
}

.widget .widget-slide .owl-nav {
    position: absolute;
    top: 50px;
    right: 44px
}

.widget-slide .owl-next {
    line-height: 21px
}

.widget-slide .owl-nav>div {
    width: 35px;
    height: 35px;
    display: inline-block;
    background: #00bad0;
    border-radius: 5px;
    margin: 4px;
    text-align: center;
    font-size: 0;
    color: #fff;
    padding: 6px
}

.comment-img,
.comment-img img {
    border-radius: 100%;
    float: left
}

.widget-slide .owl-nav>div:hover {
    background: #fff;
    color: #00bad0
}

.widget-recent-post .widget-slide-content>span {
    color: #797979
}

.single-item .comments span {
    display: inline-block;
    color: #00bad0
}

.blog-d-dec h2 {
    font-size: 24px;
    margin: 21px 0;
    line-height: 33px;
    padding-right: 22%
}

.blog-d-dec ul li,
blockquote {
    line-height: 36px
}

blockquote {
    background: #f8fcfd;
    padding: 56px;
    font-size: 23px;
    font-weight: 100;
    font-style: italic;
    color: #333;
    font-family: OpenSansLight;
    box-shadow: 0 0 3px 0 #d6d2d2
}

.share-hover>ul li>a:hover {
    background: #fff;
    color: #333
}

a.socila-fb {
    color: #4867aa
}

a.socila-tw {
    color: #1da1f2
}

a.socila-sk {
    color: #00aff0
}

a.socila-pin {
    color: #bd081b
}

a.socila-ins {
    color: #ffac4a
}

a.social-links {
    color: #fff;
    font-size: 15px
}

.social-links a {
    margin: 5px
}

.social-links {
    margin-top: 29px;
    border-top: 1px solid #efefef;
    padding-top: 10px
}

.comment-area {
    padding: 50px 0;
    margin-bottom: 75px
}

.comment-img {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    clear: both;
    margin-right: 16px;
    margin-left: -43px
}

.comment-img img {
    width: 100%;
    height: 100%
}

.comment-content {
    display: block;
    overflow: hidden;
    color: #333
}

.comment-body {
    border: 1px solid #f6f6f6;
    padding: 30px 30px 19px 0;
    border-radius: 10px;
    color: #333
}

.comment-time {
    margin-right: 0;
    color: #7d7a7a
}

.about-img img {
    width: 100%;
    border-radius: 50px 10px;
    box-shadow: 15px 15px 0 0 #00000017
}

.comment-reply a {
    margin: 4px
}

.our-service-contents h2,
h3.comment-title {
    font-size: 15px;
    font-weight: 700
}

.comment-content>p {
    margin: 9px 0;
    font-weight: 400;
    color: #6e789b
}

.comment-time:before {
    content: "\f017";
    font-family: fontawesome;
    margin-right: 5px
}

p.bookbark {
    background: #13be13;
    padding: 50px;
    font-size: 23px;
    line-height: 38px;
    margin: 30px 0;
    border-radius: 5px
}

.comment-area h2,
h5.contact-widget-title span {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 24px
}

a.Repost:before {
    content: "\f112";
    font-family: fontawesome;
    margin: 6px
}

a.reply:before {
    content: "\f122";
    font-family: fontawesome;
    margin: 5px
}

.comment-reply a {
    color: #7d7a7a
}

li.single-comment {
    margin: 40px
}

ol.comment-list-reply {
    margin-left: 95px
}

.comment-area .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ececec;
    outline: 0;
    box-shadow: none
}

.contact-page .comment-area {
    margin-bottom: 0;
    padding-top: 0
}

.contact-page .contact-form {
    padding: 0;
    margin: 0;
    background: 0 0;
    border: none
}

.contact-page .contact-form input.form-control {
    border: 1px solid #ced4da
}

h5.contact-widget-title span {
    position: relative;
    padding-bottom: 2px;
    margin-bottom: 20px
}

h5.contact-widget-title span:after {
    content: "";
    height: 1px;
    left: 0;
    bottom: 0
}

h5.contact-widget-title.no-margin {
    margin-bottom: 22px
}

.textwidget>p {
    margin-bottom: 14px
}

.textwidget ul li label {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid #6e789b;
    text-align: center;
    padding: 3px;
    margin-right: 10px
}

.textwidget ul li {
    margin-bottom: 7px
}

.gmap3 {
    margin: 20px auto;
    border: 1px dashed #f3f1f1;
    height: 500px
}

.cluster {
    color: #fff;
    font-weight: 700
}

.cluster-1 {
    line-height: 53px;
    width: 53px;
    height: 52px
}

.cluster-2 {
    line-height: 53px;
    width: 56px;
    height: 55px
}

.cluster-3 {
    line-height: 66px;
    width: 66px;
    height: 65px
}

.navbar-light .navbar-nav .nav-link {
    color: #404040
}

.btco-menu li>a {
    padding: 10px 15px;
    color: #000
}

.btco-menu .active a:focus,
.btco-menu li a:focus,
.navbar>.show>a:focus {
    background: 0 0;
    outline: 0
}

.dropdown-menu .show>.dropdown-toggle::after {
    transform: rotate(-90deg)
}

.ring:before {
    content: '';
    border-radius: 50%;
    border: 1px solid #7a64cb;
    height: 30px;
    width: 100px;
    position: absolute
}

.ring1:before {
    left: 40px;
    width: 20px;
    top: 12px;
    height: 6px;
    animation: 2.5s infinite spinner
}

.ring2:before {
    left: 35px;
    width: 30px;
    top: 10.5px;
    height: 9px;
    animation: 2.5s .1s infinite spinner
}

.ring3:before {
    left: 30px;
    width: 40px;
    top: 9px;
    height: 12px;
    animation: 2.5s .2s infinite spinner
}

.ring4:before {
    left: 25px;
    width: 50px;
    top: 7.5px;
    height: 15px;
    animation: 2.5s .3s infinite spinner
}

.ring5:before {
    left: 20px;
    width: 60px;
    top: 6px;
    height: 18px;
    animation: 2.5s .4s infinite spinner
}

.ring6:before {
    left: 15px;
    width: 70px;
    top: 4.5px;
    height: 21px;
    animation: 2.5s .5s infinite spinner
}

.ring7:before {
    left: 10px;
    width: 80px;
    top: 3px;
    height: 24px;
    animation: 2.5s .6s infinite spinner
}

.ring8:before {
    left: 5px;
    width: 90px;
    top: 1.5px;
    height: 27px;
    animation: 2.5s .7s infinite spinner
}

.ring9:before {
    width: 100px;
    height: 30px;
    animation: 2.5s .8s infinite spinner
}

@keyframes spinner {

    0%,
    100% {
        transform: translateY(10px);
        animation-timing-function: cubic-bezier(.455, .030, .515, .955)
    }

    50% {
        transform: translateY(60px);
        animation-timing-function: cubic-bezier(.455, .030, .515, .955)
    }
}

.preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999999999;
    background-color: rgb(0 0 0 / 84%)
}

.overlay,
.whirlpool {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0
}

.whirlpool {
    width: 68px;
    height: 50px;
    margin: auto
}

.main_banner_slider .slider-img img {
    display: initial !important;
    min-height: 80vh;
    max-height: 80vh;
    object-fit: cover;
    height: 100%;
    width: 100%
}

.brand_sec .row {
    justify-content: space-between;
    align-items: center
}

.brand_sec .logo {
    width: 120px
}

.header-top .social ul li a {
    color: #2d3392;
    padding-right: 22px
}

.header-top .row {
    align-items: center;
    justify-content: space-between
}

.ft-logo img {
    width: 150px
}

.footer-inner {
    padding-bottom: 1rem !important
}

.single-blog-content .blue-bg {
    background: #28a745 !important
}

.overlay {
    background: #000000a3;
    z-index: 0
}

footer.footer-section {
    margin-top: 50px
}

.sticky-menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: height .3s ease-in-out;
    box-shadow: 0 5px 5px rgb(0 0 0 / 32%)
}

svg.scroll_top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #fff;
    height: 35px;
    width: 35px;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 3px 6px 1px #00000059;
    cursor: pointer
}

.main_banner_slider a {
    background: #2d3392;
    color: #fff;
    border-color: #2d3392
}

.main_banner_slider a:hover {
    background: #28a745;
    color: #fff;
    border-color: #28a745
}

.data-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 2px
}

.data-loader>div:last-child {
    position: relative;
    width: 80px;
    height: 15px;
    margin: 1em auto
}

.data-loader>div:last-child>div {
    position: absolute;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #28a745;
    animation-timing-function: cubic-bezier(0, 1, 1, 0)
}

.data-loader>div:last-child>div:first-child {
    left: 8px;
    animation: .6s infinite data-loader-1
}

.data-loader>div:last-child>div:nth-child(2) {
    left: 8px;
    animation: .6s infinite data-loader-2
}

.data-loader>div:last-child>div:nth-child(3) {
    left: 32px;
    animation: .6s infinite data-loader-2
}

.data-loader>div:last-child>div:nth-child(4) {
    left: 56px;
    animation: .6s infinite data-loader-3
}

@keyframes data-loader-1 {
    0% {
        transform: scale(0)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes data-loader-2 {
    0% {
        transform: translate(0, 0)
    }

    100% {
        transform: translate(24px, 0)
    }
}

@keyframes data-loader-3 {
    0% {
        transform: scale(1)
    }

    100% {
        transform: scale(0)
    }
}

.overlayBanner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0000007a;
    z-index: 1
}

.working-time span {
    font-weight: 600;
    margin-right: 5px
}

.contribut-icon svg {
    font-size: 24px;
    color: #28a745
}

.careers-card {
    display: block;
    position: relative;
    background-repeat: no-repeat !important;
    width: 100%;
    border: 1px solid #cdcdcd;
    overflow: hidden !important;
    border-radius: 5px
}

.careers-card .content-mask {
    display: inline-block;
    background: rgba(255, 255, 255, .9);
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    z-index: 5
}

.careers-card .horizontal {
    display: inline-block;
    position: relative;
    background: linear-gradient(to top right, rgba(255, 255, 255, .9) 50%, transparent 0);
    height: 101%;
    width: 20%;
    top: -5%;
    right: .8%;
    overflow: hidden
}

.content-container {
    margin-right: -5vw
}

.category {
    display: inline-block;
    background: #2d3392;
    color: rgba(255, 255, 255, .9);
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 5px;
    padding: 0 15px;
    z-index: 9;
    margin-bottom: 12px
}

.category.python {
    background: #2a9d5a
}

.category:hover {
    box-shadow: 0 0 1px rgba(10, 96, 58, .2), 0 3px 6px rgba(39, 44, 49, .25)
}

.careers-card h1 {
    background: 0 0;
    color: rgba(0, 0, 0, .9);
    font-family: Nunito, "Open Sans", sans-serif;
    font-size: 17px;
    font-weight: 800;
    line-height: 1.2;
    height: auto;
    border-bottom: 1px solid rgba(204, 204, 204, .75);
    margin-top: 0;
    margin-bottom: .35em;
    padding-bottom: 5px
}

.careers-card p {
    font-size: 13px;
    line-height: 21px;
    width: 100%;
    margin-top: .3em;
    padding-left: 2px;
    z-index: 9;
    margin-bottom: .5rem;
    color: #696969;
    text-align: justify
}

.post-detail {
    color: #28a745;
    padding-left: 2px;
    vertical-align: middle
}

.post-detail .icon svg {
    position: relative;
    top: 2.25px;
    margin-right: 3px;
    font-size: 17px;
    color: #2d3392
}

.post-detail .date {
    color: rgba(0, 0, 0, .65);
    font-size: .85em
}

.credit {
    position: relative;
    font-family: Nunito Helvetica, sans-serif;
    font-size: 1.5em;
    font-weight: 200;
    text-align: center;
    bottom: .75em;
    margin-bottom: 10vh
}

.credit .creator-link {
    color: #000;
    font-family: "Kaushan Script", Lato, Helvetica, sans-serif;
    font-size: 1.05em;
    font-weight: 600;
    letter-spacing: .5px;
    text-decoration: none;
    transition: .3s linear
}

.credit .creator-link:hover {
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, .6);
    transition: 350ms linear
}

span.icon {
    font-size: 12px;
    color: rgba(0, 0, 0, .9);
    font-weight: 700
}

.careers_apply_form h2.popin {
    font-size: 22px;
    margin-bottom: 1rem
}

.careers_apply_form .contact-form {
    padding: 22px 30px;
    background-color: #f0f9fa;
    border: 1px dashed #c3cae1;
    text-align: center
}

.our-service-img img {
    width: 85px
}

.our_services_card {
    margin-bottom: 2rem;
    padding: 2rem 0;
    border-radius: 20px;
    box-shadow: 0 0 25px 0 #00000026;
    text-align: center;
    cursor: pointer
}

.our_services_card:hover {
    box-shadow: 0 0 25px 0 #0000006b
}

.our_services_sec .row {
    justify-content: center
}

p.error-message {
    color: #dc3545;
    font-size: 12px;
    font-weight: 500
}

button {
    cursor: pointer
}

.careers_apply_form select {
    border: none;
    color: #8f8f8f
}

.whyBwe-icon {
    float: left;
    border: 1px solid #c9c9c9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    margin: 10px;
    border-radius: 5px;
    width: auto;
    min-height: auto
}

.career_hire_card .contribut-icon {
    width: auto;
    float: initial;
    min-height: auto
}
.career_hire_card {
    text-align: center;
    padding-bottom: 35px;
}
.parse_input_statement {
    border: 1px dotted #b3b3b3;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 1rem;
    background: #fff;
    align-items: center;
}
.parse_input_statement svg {
    font-size: 30px;
    color: #838383;
}
.parse_input_statement label {
    font-size: 13px;
    color: #6f6f6f;
    font-weight: 600;
}
.uploaded_file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ffffff;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 1rem;
    padding: 10px 5px;
    padding-right: 10px;
}
svg.dlt_pdf {
    color: #dc3545;
    margin-top: 5px;
}

.uploaded_file h5 {
    text-transform: capitalize;
    font-size: 14px;
    color: #6b6b6b;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.uploaded_file h5 svg {
    margin: 0;
    color: #28a745;
    font-size: 17px;
    margin-right: 5px;
}
.uploaded_file div {
    display: grid;
}
.uploaded_file div svg {
    font-size: 16px;
    cursor: pointer;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide arrows in Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.apply_nw_banner_btn {
    padding: 12px 65px;
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 1.5em;
}